<template>
  <div
    :class="
      errors
        ? 'p-12 border-2 border-dashed rounded-md border-red-400 flex-wrap'
        : 'p-12 border-2 border-dashed rounded-md border-gray-400 flex-wrap'
    "
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
    class="w-full"
  >
    <input
      type="file"
      class="absolute w-px h-px overflow-hidden opacity-0"
      @change="onChange"
      ref="file"
      accept=".xls, .xlsx,.csv"
    />
    <div class="flex items-center w-full h-full upload-center">
      <div class="sm:w-1/5 2xl:w-full">
        <img src="@/assets/img/file-icon.png" class="file-icon" />
      </div>

      <div class="sm:w-4/5 2xl:w-full">
        <!-- Explain to our users they can drop files in here
            or <span class="underline">click here</span> to upload their files -->
        <span style="color:#6d6d75;font-size: 14px;"
          ><span style="color:#000000;">Upload</span> or
          <span style="color:#000000;">Drag</span> and Drop your excel or CSV
          file</span
        >
        <button class="block upload-button" @click="onClickUpload">
          <span>Upload Excel File</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadExcel',
  data: function() {
    return {
      file: null,
    }
  },
  props: {
    errors: {
      required: false,
      defatult: '',
    },
  },
  created() {
    console.log('this.errors = ', this.errors)
  },
  methods: {
    onChange() {
      let input = this.$refs.file
      let file = input.files

      if (file && file[0]) {
        let reader = new FileReader()
        reader.onload = (e) => {
          this.previewAvatar = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    remove(i) {
      this.filelist.splice(i, 1)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    onClickUpload() {
      this.$refs.file.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.file-icon {
  width: 55px;
  height: 65px;
}
.upload-button {
  width: 135px;
  height: 35px;
  margin-top: 10px;
  background: #eaeaea;
  border-radius: 5px;
  span {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
</style>
