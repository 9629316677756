<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <AppInput
        v-model="form.iot_type"
        type="richselect"
        name="Iot Type"
        label="Iot Type"
        placeholder="Select a type"
        rules="required"
        value-attribute="display_name"
        text-attribute="display_name"
        :options="iotTypes"
        hide-search-box
      />

      <AppInput
        v-model="form.iot_manufacturer"
        type="richselect"
        name="Iot Manufacturer"
        label="Iot Manufacturer"
        placeholder="Select a Manufacturer"
        rules="required"
        value-attribute="name"
        text-attribute="name"
        :options="iotManufacturers"
        hide-search-box
      />

      <AppInput
        v-model="form.vehicle_type"
        type="richselect"
        name="Vehicle Type"
        label="Vehicle Type"
        placeholder="Select a Type"
        rules="required"
        value-attribute="value"
        text-attribute="text"
        :options="vehicleTypes"
        hide-search-box
      />
      <AppInput
        v-model="form.vehicle_manufacturer"
        type="richselect"
        name="Vehicle Manufacturer"
        label="Vehicle Manufacturer"
        placeholder="Select a Manufacturer"
        rules="required"
        value-attribute="name"
        text-attribute="name"
        :options="vehicleManufactures"
        hide-search-box
      />

      <AppInput
        v-model="form.vehicle_model"
        type="richselect"
        name="Vehicle Models"
        label="Vehicle Models"
        placeholder="Select a Model"
        rules="required"
        valueAttribute="title"
        textAttribute="title"
        :options="vehicleModels"
        hide-search-box
      />

      <AppInput
        v-model="form.vehicle_amount"
        type="number"
        name="Number of Vehicles Owned"
        label="Number of Vehicles Owned"
        placeholder="e.g. 50"
        min="0"
        rules="required"
      />

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { saveAs } from 'file-saver'
export default {
  name: 'AddMultipleScooterStep1',

  props: {
    iotTypes: {
      type: Array,
      required: true,
    },
    iotManufacturers: {
      type: Array,
      required: true,
    },
    vehicleTypes: {
      type: Array,
      required: true,
    },
    vehicleManufactures: {
      type: Array,
      required: true,
    },
    vehicleModels: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      form: {
        iot_type: '',
        iot_manufacturer: '',
        vehicle_type: '',
        vehicle_manufacturer: '',
        vehicle_model: '',
        vehicle_amount: 50,
      },
      columnHeader: [
        { key: 'iot_name', title: 'IOT Name*' },
        { key: 'iot_manufacturer', title: 'IOT Manufacturer*' },
        { key: 'iot_type', title: 'IOT Type*' },
        { key: 'iot_imei', title: 'IOT IMEI*' },
        { key: 'iot_firmware_type', title: 'IOT Firmware Type' },
        { key: 'iot_firmware_key', title: 'IOT Firmware Key' },
        { key: 'iot_iccid_number', title: 'IOT ICCID Number' },
        { key: 'iot_phone_number', title: 'IOT Phone Number' },
        { key: 'vehicle_type', title: 'Vehicle Type*' },
        { key: 'vehicle_name', title: 'Vehicle Name*' },
        { key: 'vehicle_manufacturer', title: 'Vehicle Manufacturer*' },
        { key: 'vehicle_qr_code', title: 'Vehicle QR Code*' },
        { key: 'vehicle_model', title: 'Vehicle Model*' },
      ],
      // generativeColumnData: [],
    }
  },

  methods: {
    async submit() {
      this.$refs.submitButton.click()
    },

    csvMaker() {
      const csvRows = []

      const headers = this.columnHeader.map((x) => x.title)

      csvRows.push(headers.join(';'))
      for (let i = 0; i < this.form.vehicle_amount; i++) {
        let rowData = []
        this.columnHeader.map((headerItem) => {
          if (this.form[headerItem.key]) {
            rowData.push(this.form[headerItem.key])
          } else {
            rowData.push('')
          }
        })

        const values = rowData.join(';')
        csvRows.push(values)
      }

      return csvRows.join('\n')
    },

    async submitStep() {
      console.log('csvMaker', this.csvMaker())
      const blob = new Blob([this.csvMaker()], { type: 'text/csv' })
      const fileName = `${this.form.vehicle_type}_${this.form.vehicle_amount}.csv`
      saveAs(blob, fileName)
      this.$emit('save', { step: 1, data: {} })
    },
  },
}
</script>
